// Minimized Sidebar
.sidebar-minimized {
  .sidebar {
    z-index: $zindex-sticky - 1;

    .sidebar-scroll {
      overflow: visible;
      @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
    }

    .sidebar-nav {
      overflow: visible;
      @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
    }

    .nav {
      @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
    }

    .d-minimized-none,
    .nav-divider,
    .nav-label,
    .nav-title,
    .sidebar-footer,
    .sidebar-form,
    .sidebar-header {
      display: none;
    }

    // Temporary fix for this issue: https://github.com/coreui/coreui-free-bootstrap-admin-template/issues/404
    // ToDo: find better solution
    .sidebar-minimizer {
      position: fixed;
      bottom: 0;
      width: $sidebar-minimized-width;
      height: $sidebar-minimizer-height;
      background-color: #f5f7f7
    }

    .sidebar-minimizer:hover {
      position: fixed;
      bottom: 0;
      width: $sidebar-minimized-width;
      height: $sidebar-minimizer-height;
      background-color: #2facb2;
    }
    // stylelint-disable no-duplicate-selectors
    .sidebar-nav {
      padding-bottom: $sidebar-minimizer-height;
    }
    // Temporary fix end

    .sidebar-minimizer::before {
      width: 100%;
      transform: rotate(-180deg);
    }

    .nav-item {
      width: $sidebar-minimized-width;
      overflow: hidden;

      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
        overflow: visible;

        > .nav-link {
          background:  #eee;

          .nav-icon {
            color: #333;
          }
        }
        .nav-link {

          &.disabled,
          :disabled {
            background: $sidebar-nav-link-disabled-bg;

            .nav-icon {
              color: $sidebar-nav-link-disabled-icon-color;
            }
          }
        }
      }
    }
    // temp fix for vue version
    section {
      :not(.nav-dropdown-items) > {
        .nav-item:last-child::after {
          display: block;
          margin-bottom: $sidebar-minimizer-height;
          content: "";
        }
      }
    }
    // temp fix end

    .nav-link {
      position: relative;
      padding-left: 0;
      margin: 0;
      white-space: nowrap;
      border-left: 0;

      .nav-icon {
        display: block;
        float: left;
        width: $sidebar-minimized-height;
        font-size: 18px;
      }

      .badge {
        position: absolute;
        right: 15px;
        display: none;
      }

      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
        background: $sidebar-nav-link-hover-bg;

        .badge {
          display: inline;
        }
      }

      &.nav-dropdown-toggle::before {
        display: none;
      }
    }

    .nav-dropdown-items {
      .nav-item {
        width: $sidebar-width;

        .nav-link {
          width: $sidebar-width;
        }
      }
    }

    .nav > .nav-dropdown {
      > .nav-dropdown-items {
        display: none;
        max-height: 1000px;
        background: $sidebar-bg;
      }

      &:hover {
        background: #333;
        > .nav-dropdown-items {
          position: absolute;
          left: $sidebar-minimized-width;
          display: inline;
        }
      }
    }
  }
}

*[dir="rtl"] {
  .sidebar-minimized {
    .sidebar {
      .nav {
        // list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
        .divider {
          height: 0;
        }
      }

      .sidebar-minimizer::before {
        width: 100%;
        transform: rotate(0deg);
      }

      .nav-link {
        padding-right: 0;

        .nav-icon {
          float: right;
        }

        .badge {
          right: auto;
          left: 15px;
        }

        &:hover {
          .badge {
            display: inline;
          }
        }
      }

      .nav > .nav-dropdown {
        > .nav-dropdown-items {
          display: none;
          max-height: 1000px;
          background: $sidebar-bg;
        }

        &:hover {
          background: $sidebar-nav-link-hover-bg;
          > .nav-dropdown-items {
            position: absolute;
            left: 0;
            display: inline;
          }
        }
      }
    }
  }
}


*[dir="rtl"] {
.sidebar {
  .nav-dropdown-toggle::before {
    position: absolute;
    right: auto;
    left: $sidebar-nav-link-padding-x;
    transform: rotate(180deg);
  }

  .nav-dropdown.open {
    > .nav-dropdown-toggle::before {
      transform: rotate(270deg);
    }
  }

  .nav-link {
    .nav-icon {
      margin: 0 0 0 ($sidebar-nav-link-padding-x / 2);
    }

    .badge {
      float: left;
      margin-top: 2px;
    }
  }

  .nav-link.nav-dropdown-toggle {
    .badge  {
      margin-right: auto;
      margin-left: 16px;
    }
  }

  .sidebar-minimizer::before {
    right: auto;
    left: 0;
    transform: rotate(180deg);
  }
}

// Temp fix for rtl sidebar-toggler
// ToDo: find better solution
.sidebar-toggler {
  margin-right: 0 !important;
}
}