// Here you can add other styles
// stylelint-disable selector-max-class, declaration-no-important, selector-max-compound-selectors, function-url-quotes, selector-max-combinators
// back btn for pg 404 & 500 
.btn-error-home {
  color: #333;
  background-color: #f5f7f7;;
  border-color: #f5f7f7;
};


.btn-error-home:hover {
  color: #2facb2;
  background-color:#fff;
  border-color: #fff;
};

#pg-404-icon {
 color: #EE5350;
}

#pg-500-icon {
  color: #EE5350;
 }


#logo {

};

#logo-text {
  color: #333;

};

// custom input - line style
.form-control-custom {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 1px 0 #d4dae3;
  transition: box-shadow .3s ease-out;
};

// custom background style 
.bg-gd-emerald {
  background: linear-gradient(135deg, #6eab6e 0px, #2facb2 100%);
}
