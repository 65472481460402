body {
 counter-reset: courses;
 background: #fefefe;
}

.app-header .navbar-brand {
  width: auto;
}

html:not([dir="rtl"]) body:not(.sidebar-lg-show) .sidebar {
  margin-left: 0;
}

#window-refresh {
  border: none;
  background: none;
}

.btn {
  cursor: pointer;
}

.row {
  cursor: pointer;
}

.app-header .navbar-brand {
  justify-content: left;
  padding-left: 10px;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    left: 0;
    margin-left: 0;
  }

  .list-group .main-col {
    /*background: #000;*/
  }

  .list-group .main-col .row .col:nth-child(n+5) {
    display: none;
  }
}

@media (max-width: 600px) {
  .list-group .main-col .row .col:nth-child(n+4) {
    display: none;
  }
}

@media (max-width: 400px) {
  .list-group .main-col .row .col:nth-child(n+2) {
    display: none;
  }
}

.app-header .navbar-toggler {
 margin-left: 50px;
  /*display: none;
  margin-left: 50px;*/
}

.list-actions {
  margin-top: 10px;
}

.list-group-item .row .col {
  /*white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}

.list-group-item .row .col.data-email {
  /*background: red;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-fixed .app-header {
  box-shadow: 0px 1px 1px #eee;
  border: none;
}

.app-footer {
  background: none;
  border: none;
}

.sidebar {
  border-right: 1px solid #eee !important;
}

.sidebar .nav-link.active {
  background: #fefefe;
}

.sidebar .nav-dropdown.open {
  background: #eee;
}

.sui-layout-sidebar {
  max-width: 250px;
}

.sui-layout-header {
  border: none !important;
}

.list-search-none .sui-layout-header {
  padding: 10px 24px;
}

.sui-layout .sui-layout-body__inner {
  max-width: 100% !important;
}

.sui-layout-body {
  background: none !important;
}

.sui-layout-body:after {
  background: none !important;
}

.sui-search-box__submit {
  background: #20a8d8 !important;
  border-color: #20a8d8 !important;
}

#nav-slides-designer {
  text-align: center;
}
.sidebar .nav-dropdown.open .nav-link {
  color: #333;
}
.container-fluid {
  padding: 15px 30px !important;
}

.item-container.pretty {
  background: transparent;
  border: 0;
}
.item-container.pretty .e-card-label {
  display: none;
}
.item-container.pretty .e-card-label.card-label-show {
  display: inline-block;
  float:right;
  padding-right: 4px;
}
.item-container.pretty .e-card-text {
  float: none;
}
.dashboard .item-container.pretty .preview:hover {
  background-color: #000;
}
.preview.inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.e-card-image.preview .fa {
  width: 100%;
  font-size: 6rem;
  text-align: center;
}
.item-container.pretty .preview .fa {
  width: 100%;
  font-size: 6rem;
}
.item-container.pretty * {
  color: #000 !important;
}
.item-container.pretty h5 {
  margin-bottom: 0;
}
.item-container.pretty .preview {
  background: #f2f1f5;
  padding: 20px;
  text-align: center;
  /*min-height: 200px;*/
  border-radius: 10px;
}
.item-container.pretty img {
  /*width: 80%*/
  max-width:100%;
}

.slidedoc-editor-frame {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 90%;
}

.slides-edit .formio-component-form {
  display: none;
}

.slide-doc-view .formio-component-form {
  display: none;
}

.slide-doc-convert .formio-component-form {
  display: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.main-content {
  max-width: 1900px;
}

.main-content > div {
 /*padding-top: 15px;*/ }

.formio-select-autocomplete-input {
  display: none;
}
.main-content .nav-tabs {
  /*padding-bottom: 10px;*/
  margin-bottom: 20px;
}
.designer-edit {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.preview-play {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.hover {
  display: none;
  /*display: flex;*/
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index:1000;
  text-align: center;
  background-color: rgba(0,0,0,0.6);
  background-color: rgba(200,200,200,0.6);
  background-color: rgba(50,50,50,0.7);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.hover-btn {
  /*margin-top: 100px;*/
  /*margin-left: auto;
  margin-right: auto;*/
  margin-left: 2px;
  margin-right: 2px;
}

.assets-library {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index:99999;
}
.assetDialog {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
}

.slides-designer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 0, 0.85);*/
  background-color: rgba(0, 0, 0);
  z-index: 9999;
}
.nav-slides-designer {
  padding: 5px
}
.nav-slides-designer button {
  margin-right: 10px
}
.nav-slides-designer label {
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
}
.slideDesigner {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
}

.text-muted {
  color: #fff !important;
}

.form-submission-item-edit .slides-content-edit {
  margin-right: 400px;
}

.modal-dialog.modal-lg {
  height:  80%;
}

.modal-dialog.modal-lg .modal-content {
  height: 100%;
}

.modal-frame-body .form-submission-create .nav-tabs {
  display: none;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  color: #000;
}

.formio-collapse-icon {
  margin-right: 20px;
}

.form-group.formio-component.hidden {
  display: none;
}

.form-group.formio-component.required {
  font-weight: bold;
  /*color: red;*/
}

.form-group.formio-component.required:before {
  content: '*';
  font-weight: bold;
  /*color: red;*/
}
.grid-cards .card-body {
  padding: 0.2rem;
}

.slides-preview .section {
  padding: 1rem;
  /*margin-bottom: 1rem;*/
  padding-bottom: 1rem;
  border-bottom: 1px solid #cecece;
}

.slides-preview h1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: -1rem;
  font-size: 1.5rem;
}

.slidedocs-preview {
  /*position: fixed;
  top: 0;
  right: 0;
  bottom: 0;*/
  /*width: 400px;*/
  /*max-width: 800px;*/
  overflow-y: scroll;
}

.slidedocs-preview .section {
  padding: 1rem;
  margin-bottom: 2rem;
  /*padding-bottom: 1rem;*/
  padding-bottom: 0;
  border-bottom: 1px solid #cecece;
}

.page {
  break-after: page;
  page-break-before: always;
  page-break-after: always;
}

.page-section {
  page-break-before: always;
  page-break-after: avoid;
}

.page-section.page-section-first {
  page-break-before: avoid;
}

.page-slide {
  break-after: page;
  page-break-before: avoid;
  page-break-after: always;
}

.page-row {
  break-after: page;
  page-break-before: avoid;
  page-break-after: avoid;
}

.page-row.page-row-break {
  break-after: page;
  page-break-before: avoid;
  page-break-after: always;
}

.slidedocs-print h3 {
  margin-left: 10px;
}

.slidedocs-print {
  max-width: 1000px;
  overflow-y: scroll;
  margin-left: 20px;
  margin-right: 20px;
}

.slidedocs-print .slide-iframe-container {
  overflow-y: hidden !important;
}

.app-header .navbar-nav {
  margin-left: 110px;
}

.submissions-body {
  margin-top: 20px;
}

.grid-cards {
  margin-top: 20px;
}

.welcome {
  border-radius: 10px;
  margin-top: 20px;
    /*height: 290px;*/
    height: 270px;
    /*border-bottom: 50px solid #f26621;*/
    /*background: #f8981c;*/
    /*background: linear-gradient(rgba(9, 88, 71, 0.7), rgba(11, 103, 83, 0.9)), url('/assets/img/wallpaper.jpg') no-repeat right bottom fixed;*/
    background: linear-gradient(rgba(6, 56, 143, 0.7), rgba(31, 76, 154, 0.9)), url('/assets/img/wallpaper.jpg') no-repeat right bottom fixed;
    background-size: cover;
}

.welcome *{
  color: #fff;
}

.welcome .card-footer {
  background-color: transparent !important;
}

.account-logo {
  /*background: url('https://www.facilitiesfirst.com.au/wp-content/themes/facilities_first/assets/images/logo.png') no-repeat;*/
  background-position: right top;
  height: 80px;
  width: auto;
}
  
.button-block {
  margin-top: 20px;
}
.button-block .btn {
  margin-right: 4px;
}
.button-block .float-right .btn {
  margin-right: 0;
}
.button-block .link {
  margin-right: 20px;
}
.card-header-actions {
  display: none;
}

.slidedocs-print .slidedoc-iframe-container {
  overflow-y: hidden !important;
}

@media print {
  .slidedocs-print .button-block {
    display: none;
  }
  /*.slidedocs-print {
    max-width: 10cm;
  }*/
}

iframe#modal-frame.modal-frame {
  min-height: 400px;
  position: absolute;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form-control.ui.fluid.dropdown {
  height: auto !important;  
}

.slidedocs-print .viewContext {
  display: none;
}

.slidedocs-preview h1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: -1rem;
  font-size: 1.5rem;
}

.slidedoc-preview-container {
  min-width: 200px;
  min-height: 150px;
}

.slides-content-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  overflow-y: scroll;
}

.slide-preview-container {
  min-width: 200px;
  min-height: 150px;
}

.slide-iframe-container.slide-preview  {
  width: 100%;
  padding-top: 75%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.slide-iframe-container {
  background: #222;
}

.slide-container {
  /*background: #fff;*/
}

.slide-container .card-body {
  padding: 0;
}

.slidedoc-container {
  background: #fff;
}

.slidedoc-container .card-body {
  padding: 0;
}

.slidedoc-container .card-body .slide-resources {
  padding: 1rem;
}

.form-submission-item .text-muted {
  color: #777 !important;
  font-size: 12px;
  font-style: italic;
}

.slidedoc-container .card-body .slide-card-text {
  padding: 1rem;
}

.slidedoc-iframe-container {
  width: 100%;
  padding-top: 75%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.formio-component-courses .col {
  padding: 0.2rem;
}

.nav .alert {
  margin-bottom: 0;
  padding-top: 9px;
  width: 25%;
}

/*.formio-component-courses .card {
  padding: 0;
}*/

.formio-component-courses {
  counter-reset: modules;
}

.formio-component-coursesPanel > div > div > span {
  font-weight: bold;
  font-size: 20px;
}

.formio-component-coursesPanel > div > div > span::after {
  counter-increment: courses;
  content: " " counter(courses) " ";
  font-weight: bold;
  font-size: 20px;
}

.formio-component-modules {
  counter-reset: modules;
}

.formio-component-modulesHeading > h3::after {
  counter-increment: modules;
  content: " " counter(courses) "." counter(modules) " ";
  font-weight: bold;
}

.formio-component-slides {
  counter-reset: slides;
}

.form-submission-item-view .links {
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-submission-item-view .links button {
  /*margin-right: 10px;*/
}

.form-submission-item-view .formio-component-modulesSlidesHeading > h4::after {
  counter-increment: slides;
  content: " " counter(courses) "." counter(modules) "." counter(slides) " ";
  font-weight: bold;
}

.datagrid-table.table-bordered {
  border: none;
}

.datagrid-table.table-bordered th, .datagrid-table.table-bordered td {
  border: none;
}

.e-card-label {
  font-size:8px;
  color: #999;
}

.e-card-body {
  padding: 0.2em;
}

.e-card-text > div {
  display: inline;
}

.e-card-text {
  float: right;
}

.e-card-text-true {
  color: green;
  font-weight: bold;
}

.e-card-text-false {
  color: red;
  font-weight: bold;
}

.thumbnails {
  /*min-height: 180px;*/
  margin-bottom: 5px;
  max-height: 400px;
  overflow-y: auto;
}

.e-card-image {
  display: block;
  /*background: #000;*/
}

.e-card-image.sml {
  /*(width: 100px;*/
  margin: 2px;
}

.block {
  /*display: inline-block;*/
  display: block;
  margin-right: 2px;
}

.sui-layout-sidebar {
  display: none;
}
.sui-layout-main {
  width: 100% !important;
  padding: 0 !important;
}
.submissions-body {
  margin-top: 10px;
}
.submissions-list-footer {
  margin-top: 20px;
}
.tags-list span {
  border: 1px solid #3a9d5d;
  background-color: rgba(58,157,93, 0.2);
  display: inline-block;
  font-size: 8px;
  margin-bottom: 2px;
}
.viewContext {
  display: none;
  margin-left: 5px;
  font-size: 10px;
  color: #333;
}
.slide-text {
  padding: 10px;
}
.hide {
  display: none;
}

#root.hide {
  display: none;
}
.fa.danger {
}
.fa.success {
}
#version {
  position: fixed;
  bottom: 4px;
  left: 4px;
  z-index: 9999;
  color: #999;
  font-size: 8px;
}
