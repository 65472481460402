#signin-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  color: #32A856;
  margin: 0;
  text-align: center;
};

.signin-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #737373
};

#sign-up-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  color: #32A856;
};

#sign-up-card {
  background: #f5f7f7;
};


#signin-container {
  height: 100vh;
};

#signin-continue-btn {
  margin-top: 40px;
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
  height: auto;
  border-radius: 50px;
  width: 48%;
  color: #144b4d;
  background-color: #d3f2f3;
  border-color: #d3f2f3;
  margin-bottom: 16px;
};

#signin-continue-btn:hover {
  background-color: #c5e1f7;
  border-color: #c5e1f7;
}

#signin-header-text1 {
  color: #171717;
  font-weight: 100;
  font-size: 2.57143rem;
  margin-top: 50px;
  margin-bottom: 10px;
};

#signin-header-text2 {
  font-weight: 100;
  margin-bottom: 0;
  font-size: 2.14286rem;
  padding-bottom: 30px;
};

#signin-ptag1 {
  margin-bottom: 4px;
  color: #575757;
};

#signin-ptag2 {
  color: #575757;
};

#signin-account-input {
  // input style box to a line
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 1px 0 #dcdfe3;
  transition: box-shadow .3s ease-out;

  color: #333;
  font-size: 1.14286rem;
  font-weight: 400;
};

#signin-account-input::placeholder {
  color: #575757;
};
