#signin2-background-image {
  height: 100vh;
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1519999482648-25049ddd37b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1526&q=80");
  overflow-x:hidden;
  overflow-y: hidden;
}

.auth-account .container {
  max-width: 400px;
  margin-top: 10%;
}

#signin2-card {
  height: 100vh;
};

.auth-account #signin2-card {
  height: 50vh;
  width: 100%;
};

#signin2-text {
  position: fixed;
  bottom: 0;
  padding-left: 35px;
};

#signin2-text1 {
  font-size: 26px;
  color: #fff;
  font-weight: 600;
  font-family: sans-serif;
  line-height: 1.6;
  margin-bottom: 24px;
};

#signin2-text2 {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  font-style: italic;
  line-height: 1.6;
  margin-bottom: 24px;
}

#copyright-logo {
  margin-right: 7px;
}

#signin2-create-forgot-btns {
  /*margin-left: -13px;*/
  margin-top: 20px;
};

a {
  cursor: pointer;
}

#signin2-logo {
  margin-bottom: 25px;
}

#signin2-btn-login {
  margin-top: 20px;
  padding: 11px 34px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
    color: #144b4d;
    background-color: #d3f2f3;
    border-color: #d3f2f3;
};

#signin2-btn-login:hover {
  background-color: #c5e1f7;
  border-color: #c5e1f7;
};


#signin2-username-input {
  // input style box to a line
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 1px 0 #dcdfe3;
  transition: box-shadow .3s ease-out;

  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #575757;

};

// Change placeholder text color
#signin2-username-input::placeholder {
  color: #575757;
  opacity: 1; /* Firefox */
}

#signin2-password-input {
  // input style box to a line
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 1px 0 #dcdfe3;
  transition: box-shadow .3s ease-out;

  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #575757;
};

// Change placeholder text color
#signin2-password-input::placeholder {
  color: #575757;
  opacity: 1; /* Firefox */
};

#signin2-form {
  padding: 10px 10px 1px;
};

#signin2-form a {
  padding-left: 12px;
  padding-right: 35px;
  color:#868e96;
};

#signin2-form a:hover {
  color:#575757;
 
}
