// required label asterisk
// label.required:after {
//   content: "*";
//   color: red;
// };

//Simulate placeholder functionality on input date field?
input[type="date"]:before {
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  color: #575757;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
};

.newUser-custom-input-style {
  margin-top: 26px;
  font-size: 1.142857rem;
  font-weight: 400;
  // width: 40%;
};

// Change placeholder text colour
.newUser-custom-input-style::placeholder {
  color: #575757;
};


#new-user-copyright {
  text-align: center;
  font-size: 0.9em;
  color: #999;
  margin-bottom: 30px;
  margin-top: 20px;
  padding-bottom: 30px;
    padding-top: 30px;
}

#new-user-text-header1 {
  padding-top: 60px;
};

#new-user-text-header2 {
  padding-bottom: 30px;
  color:#737373;
  font-weight: 400px;
  font-size: 1.571429rem;
};

#terms_notes {
  padding-top: 30px;
};

#newUser-signup-btn {
  margin-top: 40px;
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
  height: auto;
  color: #5c852c;
  background-color: #ebf5df;
  border-color: #ebf5df;
  border-radius: 50px;
  width: 100%;
};

#newUser-signup-btn:hover {
  background-color: #d3e9b8;
  box-shadow: none;
  border-color: #d3e9b8;
};

