#register-card-container {
  height:100vh;
};

#register-signup-btn {
  margin-top: 40px;
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
  height: auto;
  color: #5c852c;
  background-color: #ebf5df;
  border-color: #ebf5df;
  border-radius: 50px;
};

#register-signup-btn:hover {
  background-color: #d3e9b8;
  box-shadow: none;
  border-color: #d3e9b8;
};

#register-header-text1 {
  font-size: 26px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 10px;
  padding-bottom: 30px;
};

// registration input style
#registration_password {
  font-size: 1.142857rem;
  font-weight: 400;
};

// Change placeholder text colour
#registration_password::placeholder {
  color: #575757;
};

